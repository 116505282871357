import { CustomError } from 'api/api.types'
import { typeDevice } from 'common/constants'

export const detectWebCamera = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    if (process.env.browser) {
      const getUserMedia =
        navigator.mediaDevices &&
        navigator.mediaDevices.enumerateDevices &&
        typeof navigator.mediaDevices.enumerateDevices === 'function'

      if (!getUserMedia) {
        console.warn('Method enumerateDevices() not supported')
        return reject(
          new CustomError<boolean>(
            'Method enumerateDevices() not supported',
            false
          )
        )
      }

      return navigator.mediaDevices.enumerateDevices().then(
        (devices) => {
          const findVideoDevice = devices.find(
            (device) => device.kind === typeDevice.videoDeviceInput
          )
          if (findVideoDevice) {
            return resolve(true)
          } else {
            console.warn('WebCamera not detected')
            return reject(
              new CustomError<boolean>('WebCamera not detected', false)
            )
          }
        },
        (error) => {
          console.warn('Media devices error', error)
          return reject(new CustomError<boolean>('Media devices error', false))
        }
      )
    }

    return reject(new CustomError<boolean>('Запуск на ноде не доступен', false))
  })
}
