import { UniNoticeId } from 'api/uninotice/uniNoticeApi'

export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

interface PushNotificationAction {
  type: typeof PUSH_NOTIFICATION
  notification: {
    id: UniNoticeId
    type: string
  }
}
interface RemoveNotificationAction {
  type: typeof REMOVE_NOTIFICATION
  id: UniNoticeId
}

export const removeNotificationAction = (id: UniNoticeId) => ({
  type: REMOVE_NOTIFICATION,
  id,
})

export type NotificationActionTypes =
  | PushNotificationAction
  | RemoveNotificationAction
