import { notEmptyArray } from 'functions/array'

import { SearchPromoTypes } from './Search.types'

/**
 * Отношение высоты к ширине изображения в карточке.
 * Однозначно установлено в макете.
 */
export const IMAGE_RATIO = 1.4

export const PROMO_ITEMS_BY_CHUNK = 4
export const DEFAULT_SEARCH_LIMIT = 60 - PROMO_ITEMS_BY_CHUNK

export const PHOTO_SIZES = {
  SQUARE_SMALL: {
    ID: 'square_small',
    SIZE: 150,
  },
  SQUARE: {
    ID: 'square',
    SIZE: 300,
  },
  SQUARE_LARGE: {
    ID: 'squareLarge',
    SIZE: 600,
  },
}

/**
 * Промо блоки отображаются по следующему правилу:
 * Первый рекламный блок отображается вместо первой карточки профиля.
 * 2й блок отображается вместо 10 карточки профиля.
 * Каждый последующий блок отображается вместо 19 карточки профиля после предыдущего промо блока.
 */
export const insertPromoToListChunk = <T, P extends { type: SearchPromoTypes }>(
  lastListChunk: T[],
  listChunk: T[],
  promosList: P[],
  isFirstInject: boolean
) => {
  let promoPointer = 0

  if (isFirstInject) {
    // 1й блок отображается на первом месте.
    const result: (T | P)[] = [promosList[promoPointer]]
    promoPointer++
    // 2й блок отображается вместо 10 карточки профиля.
    let nextPromoIndex = 9

    listChunk.forEach((value, index) => {
      if (index === nextPromoIndex) {
        const promo = promosList[promoPointer]
        result.push(promo, value)
        promoPointer++
        // Каждый последующий блок отображается вместо 19 карточки профиля после предыдущего промо блока.
        // Поскольку мы чуть выше вставляем одну карточку профиля после промо, то вычитаем ее и получается 18.
        nextPromoIndex += 18
        return
      }

      result.push(value)
    })

    return {
      result,
      lastPromoInsert: promosList[promoPointer],
    }
  }

  // Найдем индекс последнего промо-блока в предыдущем чанке
  const lastPromoIndex = lastListChunk.findLastIndex(
    (item) => item['type'] === SearchPromoTypes.Service
  )
  // Высчитаем сколько карточек назад был промо-блок в предыдущем чанке
  const lastPromoItemsBack = lastListChunk.length - 1 - lastPromoIndex
  // Высчитаем индекс следующей карточки с промо-блоком в новом чанке
  let nextPromoIndex = 18 - lastPromoItemsBack
  const result: (T | P)[] = []

  listChunk.forEach((value, index) => {
    if (index === nextPromoIndex) {
      const promo = promosList[promoPointer]
      result.push(promo, value)
      promoPointer++
      // Каждый последующий блок отображается вместо 19 карточки профиля после предыдущего промо блока.
      // Поскольку мы чуть выше вставляем одну карточку профиля после промо, то вычитаем ее и получается 18.
      nextPromoIndex += 18
      return
    }

    result.push(value)
  })

  return {
    result,
    lastPromoInsert: promosList[promoPointer - 1],
  }
}

export const injectPromosToListChunk = <
  T,
  P extends { type: SearchPromoTypes }
>(
  listChunk: T[],
  promos: P[] = [],
  isFirstInject: boolean = false,
  lastListChunk: T[]
) => {
  if (notEmptyArray(promos)) {
    const { result, lastPromoInsert } = insertPromoToListChunk(
      lastListChunk,
      listChunk,
      promos,
      isFirstInject
    )
    return {
      searchItems: result,
      lastPromoInsert,
    }
  }

  return { searchItems: listChunk }
}

export const mapFaceCoordsToItems = (items, faceCoords) => {
  const faceCoordsMap = faceCoords.reduce((resultMap, coordsItem) => {
    resultMap[coordsItem.photoId] = coordsItem.faces
    return resultMap
  }, {})

  items.forEach((item) => {
    item.userpic.coords = faceCoordsMap[item.userpic.id]
  })

  return items
}
