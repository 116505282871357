import { AsyncAction } from 'actions/actions.types'
import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { getCoordinatesAction } from 'actions/geolocation/getCoordinatesAction'
import {
  savePhotoRatingSettingsAction,
  updatePhotoRatingSettingFieldAction,
} from 'actions/photoRating/photoRatingSettingsAction'
import { reloadVotingPhotosAction } from 'actions/rating/photoRatingAction'
import { definitions } from 'api/generated/ratings_v2.voting'
import { fetchVotingPhotoApi } from 'api/rating/fetchVotingPhotoApi'
import { photoRatingLimit } from 'components/page/PhotoRating/constants'
import { locationStringUnderscore } from 'functions/location/locationStringUnderscore'
import { Coordinates } from 'reducers/geolocation/geolocationReducer.types'

export const FETCH_VOTING_PHOTOS = 'FETCH_VOTING_PHOTOS' as const

export interface FetchVotingPhotosAction
  extends AsyncAction<definitions['RatingVotingPhotosSet']> {
  type: typeof FETCH_VOTING_PHOTOS
}

export const fetchVotingPhotosAction =
  (offsetPhotoId?: number) =>
  (dispatch: AppDispatchNext, getState: AppGetState) => {
    const {
      photoRatingSettings: {
        form: {
          ageFrom,
          ageTo,
          heightFrom,
          heightTo,
          country,
          region,
          city,
          metro,
          gender,
          coordinates: useCoordinatesSearch,
        },
      },
      authorizationReducer: { authorized },
    } = getState()
    // https://redmine.mamba.ru/issues/100618
    if (
      ageFrom === 0 &&
      ageTo === 0 &&
      country === 0 &&
      region === 0 &&
      city === 0 &&
      gender === ''
    ) {
      console.warn('Parameters are empty', {
        ageFrom,
        ageTo,
        heightFrom,
        heightTo,
        country,
        region,
        city,
        gender,
      })
      return
    }

    return dispatch({
      type: FETCH_VOTING_PHOTOS,
      promise: async () => {
        let coordinates: Coordinates | undefined = undefined

        if (useCoordinatesSearch) {
          coordinates = await dispatch(getCoordinatesAction())
        }

        if (useCoordinatesSearch && !coordinates) {
          dispatch(updatePhotoRatingSettingFieldAction('coordinates', false))
          await dispatch(savePhotoRatingSettingsAction())
        }

        const json = await fetchVotingPhotoApi(
          authorized,
          ageFrom,
          ageTo,
          heightFrom,
          heightTo,
          locationStringUnderscore([country, region, city, metro]),
          gender!,
          coordinates!,
          photoRatingLimit,
          offsetPhotoId
        )

        if (
          json?.result &&
          offsetPhotoId &&
          offsetPhotoId !== json.result.offsetPhotoId
        ) {
          dispatch(reloadVotingPhotosAction())
        }

        return json
      },
    })
  }
