import { AppDispatchNext } from 'actions/actions.typesNext'
import { goBackOrRatingPathAction } from 'actions/route/routeAction'
import { apiResultHasErrors } from 'api/function/apiResultHasErrors'
import { addUserToIgnoreFolderApi } from 'api/profileApi'

export const MOVE_TO_IGNORE_CONTACT_LIST_ASYNC =
  'MOVE_TO_IGNORE_CONTACT_LIST_ASYNC' as const

export const moveToIgnoreContactListAsyncPlainAction = (userId: number) => ({
  type: MOVE_TO_IGNORE_CONTACT_LIST_ASYNC,
  promise: () => addUserToIgnoreFolderApi(userId),
  userId,
})

export const moveToIgnoreContactListAsyncAction =
  (userId: number) => async (dispatch: AppDispatchNext) => {
    const response = await dispatch(
      moveToIgnoreContactListAsyncPlainAction(userId)
    )

    if (!apiResultHasErrors(response['result'])) {
      dispatch(goBackOrRatingPathAction())
    } else {
      console.error(response['result'])
    }
  }
