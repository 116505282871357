export enum SearchPromoTypes {
  Service = 'service',
}
export enum PromoCase {
  /** Активируй VIP-статус и узнай, кто заинтересовался твоим профилем */
  Vip = 'vip',
  /** Активируй гарантированные показы — получи больше лайков */
  GaranteedViews = 'garanteed-views',
  /** Подними свой профиль наверх в поиске, и тебя будет проще найти */
  TopUp = 'top-up',
}
export interface SearchItemPromo {
  type: SearchPromoTypes.Service
  case: PromoCase
}
