/* eslint-disable max-lines */
import { AsyncThunkAction } from 'actions/actions.types'
import { AppDispatchNext } from 'actions/actions.typesNext'
import { sendStatisticsAction } from 'actions/analytics/sendStatisticsAction'
import {
  resetPasswordEmailAction,
  resetPasswordPhoneAction,
} from 'actions/authorization/resetPasswordAction'
import { addFavoriteContactAction } from 'actions/contactList/toggleFavoriteContactAction'
import { fullResetSearchFormAction } from 'actions/mainSearchAction'
import { chatSupportOpenTicketAction } from 'actions/messenger/chatSupportOpenTicketAction'
import { fetchMessengerChatAction } from 'actions/messenger/fetchMessengerChatAction'
import { removeNotificationAction } from 'actions/notificationAction'
import { deletePhotoAction } from 'actions/photo/photoViewerAction'
import { restoreDeletedPhotoPlainAction } from 'actions/photo/restoreDeletedPhotoAction'
import { combineNormalizeRestartPhotoRatingAction } from 'actions/photoRating/photoRatingSettingsAction'
import { moveToIgnoreContactListAsyncAction } from 'actions/profile/profileIgnoredUserAction'
import { requestIncognitoAction } from 'actions/profileAction'
import { openSupportChatAction } from 'actions/route/openSupportChatAction'
import {
  goBackAction,
  goBackStorefrontAction,
  goBackToBaseUrlAction,
} from 'actions/route/routeAction'
import { clearSearchListProfileAction } from 'actions/search/searchListProfileAction'
import { resetLivenessStatusAction } from 'actions/settings/photoVerificationLivenessAction'
import {
  fetchUniNoticeAction,
  switchTypeGiftShowCaseAction,
  switchUploadAction,
} from 'actions/uninotice/uniNoticeAction'
import { confirmUpdateUserBirthDateUniNoticeAction } from 'actions/user/field/birthDateAction'
import { codeNoticeEquals } from 'api/function/codeNoticeEquals'
import { UniNotice } from 'api/uninotice/UniNotice'
import { shortcut } from 'common/constants'
import { createSupportFormPath } from 'common/createSupportFormPath'
import { metaphorSlideAdblock } from 'common-constants/metaphor'
import { isModalOpenFromState } from 'components/layout/ModalLayout/modalOpen'
import { supportFormPath } from 'components/page/Boarding/SupportForm/SupportForm.paths'
import { fieldPathList } from 'components/page/DatingProfile/DatingProfile.constants'
import { fieldNamePath } from 'components/page/DatingProfile/DatingProfile.paths'
import {
  EventListFilter,
  eventListPath,
} from 'components/page/EventList/EventList.paths'
import { profileSharingPath } from 'components/page/ProfileSharing/ProfileSharing.paths'
import { ratingPath } from 'components/page/RatingWave/Rating.paths'
import { restorePasswordPath } from 'components/page/RestorePassword/RestorePasswordLoadable'
import {
  searchPath,
  searchSettingsPath,
} from 'components/page/Search/Search.paths'
import { Fields } from 'components/page/Settings/Fields'
import {
  settingsFieldPath,
  settingsPrivacyPath,
  settingsVerificationPath,
} from 'components/page/Settings/Settings.paths'
import { openMambaAppOtpReload } from 'components/page/WebView/OneTimePasswordCode.functions'
import {
  appUniWebPath,
  chatPath,
  contactPath,
  datingProfilePath,
  downloadAppPath,
  indexPath,
  photoRestrictionsPath,
  profilePath,
  settingsModalPath,
  settingsPath as shortSettingsPath,
} from 'components/paths'
import {
  storeFrontTopUpPath,
  storeFrontVipPath,
  storeFrontGiftsPath,
  storeFrontUserPath,
  giftPresentPath,
  stopChatTextPathParameterMerge,
} from 'components/storefront/StoreFront.paths'
import {
  DISPLAY_CONTAINER,
  TYPE_NOTIFICATION,
  TYPE_SHORTCUT,
  UniNoticeData,
  UniNoticeDisplayTypes,
  UniNoticeIdTypes,
  UniNoticeType,
  UniNoticeViewTypes,
} from 'components/uninotice/UniNotice.constants'
import {
  NoticeInlineRedirect,
  InlineNoticeTypes,
  UniNoticeId,
  livenessNoticeIdsList,
} from 'components/uninotice/uninotice.types'
import {
  messagingAddFavoriteWhenIncognitoNoticeId,
  incognitoRequiredNoticeId,
  notSupportedWebCameraNoticeId,
} from 'components/uninotice/uniNoticeIdList'
import { defineStartScreenPath } from 'functions/defineStartScreenPath'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push, replace } from 'functions/router'
import { switchLinkPhotoFilterVerification } from 'functions/switchLinkPhotoFilterVerification'
import { openUrlInNewTab } from 'functions/url'

import { detectWebCamera } from '../../../client/functions/detectWebCamera'

type UniNoticeHandler = (
  data: UniNoticeData,
  noticeId: string,
  notice: UniNotice,
  type: UniNoticeViewTypes,
  display: UniNoticeDisplayTypes
) => AsyncThunkAction

export type HandlerTypes = UniNoticeHandler | undefined

const uploadPhotoAction: UniNoticeHandler =
  (_data, _noticeId, _notice, type, _display): AsyncThunkAction =>
  (dispatch) => {
    // messenger
    return dispatch(switchUploadAction(type))
  }

export const privacyAction = (): AsyncThunkAction => (dispatch, getState) => {
  const {
    userReducer: { userId: currentUserId },
    systemReducer: { baseUrl },
    photoAlbumReducer: { id: viewedUserId },
  } = getState()
  if (baseUrl === indexPath) {
    return dispatch(
      replace(mergeAllUrls(baseUrl, shortSettingsPath, settingsPrivacyPath))
    )
  } else {
    // Если попадаем в настройку приватности пользователя из фотоальбома
    const isCurrentUser = currentUserId === viewedUserId

    return dispatch(
      replace(
        mergeAllUrls(
          baseUrl,
          settingsModalPath,
          'field',
          isCurrentUser ? Fields.Photo : Fields.ProfileVisibilityStatus
        )
      )
    )
  }
}

export const incognitoAction = (): AsyncThunkAction => (dispatch, getState) => {
  const {
    systemReducer: { baseUrl },
  } = getState()

  return dispatch(
    replace(mergeAllUrls(baseUrl, settingsModalPath, 'field', 'photo'))
  )
}

export type HandlersMap = Record<UniNoticeIdTypes, HandlerTypes>

export const commonHandlersMap: HandlersMap = {
  [UniNoticeType.upload_photo]: uploadPhotoAction,
  [UniNoticeType.add_photo]: uploadPhotoAction,
  [UniNoticeType.vip_showcase]: () => (dispatch, getState) => {
    /**
     * Смысл данного решения:
     * 1. если юнинотайс открыт в рамках модала, то replace
     * 2. если в инлайново, то push
     *
     * Тогда логика навигации не должна ломаться.
     *
     * Пример:
     * 1. попали в заблокированного юзера, профиль
     *    заменен нотайсом без смены урла
     * 2. делаем пуш для открытия витрины випа
     * 3. на бек открывается нормально профиль
     */
    const modalOpen = isModalOpenFromState(getState())
    const method = modalOpen ? replace : push

    dispatch(
      method(mergeAllUrls(getState().systemReducer.baseUrl, storeFrontVipPath))
    )
  },
  [UniNoticeType.close_storefront]: () => (dispatch) =>
    dispatch(goBackToBaseUrlAction()),
  [UniNoticeType.create_support_email]: () => (dispatch, getState) =>
    // authorized === false
    dispatch(
      push(mergeAllUrls(getState().systemReducer.baseUrl, supportFormPath))
    ),
  [UniNoticeType.photo_verification]: () => (dispatch, getState) => {
    // https://redmine.mamba.ru/issues/104994 messenger
    // https://youtrack.mamba.ru/issue/M-6833#focus=Comments-4-42392.0-0

    const { userReducer, systemReducer, errorReducer } = getState()

    detectWebCamera()
      .then(
        () => {
          const url = switchLinkPhotoFilterVerification(
            systemReducer.baseUrl,
            userReducer.hasDefaultPhoto,
            errorReducer.profileBanned
          )

          dispatch(replace(url))
        },
        () => {
          dispatch(fetchUniNoticeAction(notSupportedWebCameraNoticeId))
        }
      )
      .catch((error) => {
        console.error(error)
      })
  },
  [UniNoticeType.gifts_showcase]:
    (data, noticeId, notice, type, display) => (dispatch) =>
      dispatch(
        switchTypeGiftShowCaseAction(data.recipientId as number, type, display)
      ),
  [UniNoticeType.set_privacy]: privacyAction,
  [UniNoticeType.none]:
    (data, noticeId, notice) => async (dispatch, getState) => {
      if (notice && notice.type === shortcut) {
        return
      }

      if (noticeId === UniNoticeId.user_leaves_otp_pay_page) {
        /**
         * Если не авторизовался, значит в итоге не ввел код.
         */
        if (!getState().authorizationReducer.authorized) {
          await dispatch(
            sendStatisticsAction('otp', 'returns_to_app_without_entering_otp')
          )
        }

        return openMambaAppOtpReload()
      }

      const {
        authorizationReducer: { authorized },
        systemReducer: { startScreen, locale },
      } = getState()

      const redirectNoticeInline = () => {
        if (!authorized) {
          return dispatch(replace(mergeAllUrls(locale, indexPath)))
        }

        return dispatch(replace(url))
      }

      /**
       * https://redmine.mamba.ru/issues/112514
       * https://redmine.mamba.ru/issues/114736
       * переход для определенного notice.
       */
      const url = defineStartScreenPath(startScreen)

      if (NoticeInlineRedirect.includes(noticeId as InlineNoticeTypes)) {
        return redirectNoticeInline()
      }

      if (livenessNoticeIdsList.includes(noticeId as UniNoticeType)) {
        dispatch(resetLivenessStatusAction())
      }

      /** https://youtrack.mamba.ru/issue/M-6512 billing-payment-processing */
      if (noticeId === 'billing-payment-processing') {
        dispatch(goBackStorefrontAction())
      } else {
        dispatch(goBackAction())
      }
    },
  [UniNoticeType.encounters]: () => (dispatch) =>
    // messenger
    dispatch(push(mergeAllUrls(ratingPath))),
  [UniNoticeType.edit_about_me]:
    (data, _noticeId, _notice) => (dispatch, getState) => {
      const {
        systemReducer: { baseUrl },
      } = getState()

      /**
       * Если есть поля, то первое как раз которое должны заполнить.
       * Но строку отдает бекенд, предполагаем, что это всегда первый в массиве.
       * https://youtrack.mamba.ru/issue/M-8546
       */
      if (data.fields?.length) {
        return dispatch(
          replace(
            mergeAllUrls(
              baseUrl,
              datingProfilePath,
              fieldNamePath,
              fieldPathList[data.fields?.[0]]
            )
          )
        )
      }

      dispatch(replace(mergeAllUrls(baseUrl, datingProfilePath)))
    },
  [UniNoticeType.search]: () => (dispatch) => {
    // TODO сделать плавное закрытие, а не просто исчезновение
    // dispatch(removeNotificationAction(noticeId))
    return dispatch(push(searchPath))
  },
  [UniNoticeType.search_filters]: () => (dispatch) => {
    dispatch(push(searchSettingsPath))
  },
  [UniNoticeType.coins_showcase]: () => (dispatch, getState) =>
    dispatch(
      push(mergeAllUrls(getState().systemReducer.baseUrl, storeFrontTopUpPath))
    ),
  [UniNoticeType.turn_off_incognito]: incognitoAction,
  [UniNoticeType.undelete_photo]: (data) => (dispatch) =>
    dispatch(restoreDeletedPhotoPlainAction(data.photoId!)),
  [UniNoticeType.create_support_ticket]: () => (dispatch) => {
    // https://redmine.mamba.ru/issues/101590
    return dispatch(openSupportChatAction())
  },
  [UniNoticeType.open_viber_for_profile_verification]:
    () => (dispatch, getState) => {
      const { viber: viberHref } = getState().settingsReducer.externalVerify
      return openUrlInNewTab(viberHref!)
    },
  [UniNoticeType.open_telegram_for_profile_verification]:
    () => (dispatch, getState) => {
      const { telegram: telegramHref } =
        getState().settingsReducer.externalVerify
      return openUrlInNewTab(telegramHref!)
    },
  [UniNoticeType.open_whatsapp_for_profile_verification]:
    () => (dispatch, getState) => {
      const { whatsapp: whatsappHref } =
        getState().settingsReducer.externalVerify
      return openUrlInNewTab(whatsappHref!)
    },
  [UniNoticeType.request_incognito_access]: (data) => (dispatch, getState) => {
    if (data.userId) {
      dispatch(replace(getState().systemReducer.baseUrl))
      dispatch(requestIncognitoAction(data.userId))
    } else {
      console.error('userId for request-incognito-access was not found in data')
    }
  },
  [UniNoticeType.messenger]: (data, noticeId) => (dispatch) => {
    if (!data?.userId) {
      console.error('userId for open messenger not found was not found in data')
      return
    }

    const contactUrl = mergeAllUrls(chatPath, data.userId, contactPath)

    dispatch(push(contactUrl))

    switch (noticeId) {
      case incognitoRequiredNoticeId:
        dispatch(
          push(
            mergeAllUrls(
              contactUrl,
              storeFrontGiftsPath,
              storeFrontUserPath,
              data.userId,
              giftPresentPath,
              stopChatTextPathParameterMerge
            )
          )
        )
    }
  },
  [UniNoticeType.add_to_favorite]: (data, noticeId) => async (dispatch) => {
    await dispatch(addFavoriteContactAction(data.userId!))
    if (noticeId === messagingAddFavoriteWhenIncognitoNoticeId) {
      // messenger
      dispatch(fetchMessengerChatAction({ profileId: Number(data.userId) }))
    }
  },
  [UniNoticeType.open_support_ticket]: () => (dispatch, getState) => {
    dispatch(chatSupportOpenTicketAction())
    // messenger
    return dispatch(push(getState().systemReducer.baseUrl))
  },
  [UniNoticeType.verification]: () => (dispatch, getState) => {
    const {
      systemReducer: { baseUrl },
    } = getState()
    return dispatch(
      push(
        mergeAllUrls(
          baseUrl,
          baseUrl === indexPath ? shortSettingsPath : settingsModalPath,
          settingsVerificationPath
        )
      )
    )
  },
  [UniNoticeType.upload_rules]: () => (dispatch, getState) =>
    dispatch(
      push(
        mergeAllUrls(getState().systemReducer.baseUrl, photoRestrictionsPath)
      )
    ),
  [UniNoticeType.confirm_photo_deletion]:
    (data) => async (dispatch: AppDispatchNext) => {
      dispatch(goBackAction())
      await dispatch(deletePhotoAction(data.photosIds!))
      dispatch(
        removeNotificationAction(UniNoticeType.photo_delete_confirmation)
      )
    },
  [UniNoticeType.request_mamba_password]:
    (data, noticeId, notice) => (dispatch, getState) => {
      const {
        systemReducer: { contextId, baseUrl, touch },
      } = getState()
      const url = createSupportFormPath(
        appUniWebPath,
        touch,
        contextId,
        baseUrl
      )

      if (codeNoticeEquals(notice.errorCode, 'cross_auth_phone')) {
        dispatch(resetPasswordPhoneAction(true, url))
      }

      if (codeNoticeEquals(notice.errorCode, 'cross_auth_email')) {
        dispatch(resetPasswordEmailAction(true, url))
      }
    },
  /*
     Заглушка которая ничего не делает и нужна для того чтобы проходила проверка о том что мы поддерживаем данный тип нотиса.
   */
  [UniNoticeType.login_to_teamo]: () => () => null,
  [UniNoticeType.ignore]: (data, noticeId) => (dispatch) => {
    const userId = data && data.userId

    // см. https://redmine.mamba.ru/issues/111285 и связанные задачи
    if (userId) {
      dispatch(moveToIgnoreContactListAsyncAction(userId))
      dispatch(fetchUniNoticeAction(UniNoticeId.user_added_to_ignore))
    } else {
      console.error(`cannot find userId for ignore, ${noticeId}`)
    }
  },
  [UniNoticeType.events]: () => (dispatch) => {
    dispatch(push(mergeAllUrls(eventListPath, EventListFilter.All)))
  },
  [UniNoticeType.external_messenger_open_for_auth]: (data) => () => {
    openUrlInNewTab(data.link!)
  },
  [UniNoticeType.external_link]: (data, noticeId) => (dispatch, getState) => {
    const {
      systemReducer: { web, baseUrl },
    } = getState()
    if (noticeId === UniNoticeId.action_not_supported_by_browser && web) {
      dispatch(push(mergeAllUrls(baseUrl, downloadAppPath)))
    } else {
      openUrlInNewTab(data.link!)
    }
  },
  [UniNoticeType.show_another_uninotice]: (data) => (dispatch) => {
    dispatch(fetchUniNoticeAction(data.noticeId!))
  },
  [UniNoticeType.show_this_is_me_widget]: () => (dispatch, getState) => {
    dispatch(
      push(
        mergeAllUrls(
          profilePath,
          getState().userReducer.userId!,
          profileSharingPath
        )
      )
    )
  },

  /**
   * @param data
   * @param noticeId
   * Видимо на данный тип собираются повесить много каких "confirm"
   * судя по https://redmine.mamba.ru/issues/115506#note-17
   * Поэтому делаем развилку
   */
  [UniNoticeType.confirm]: (data, noticeId) => (dispatch) => {
    switch (noticeId) {
      case UniNoticeId.dating_profile_confirm_birthdate_change:
        return dispatch(confirmUpdateUserBirthDateUniNoticeAction())
      case UniNoticeId.user_leaves_otp_pay_page:
        return dispatch(goBackAction(true))
    }
  },
  [UniNoticeType.password_recovery]: () => (dispatch, getState) => {
    dispatch(
      push(mergeAllUrls(getState().systemReducer.baseUrl, restorePasswordPath))
    )
  },
  [UniNoticeType.open_oauth]: (data) => () => {
    if (data.oauthVendor) {
      window.location.href = data.oauthVendor.url
    }
  },
  [UniNoticeType.show_vip_showcase_slide_hide_ad]:
    () => (dispatch, getState) => {
      return dispatch(
        replace(
          mergeAllUrls(
            getState().systemReducer.baseUrl,
            storeFrontVipPath,
            metaphorSlideAdblock,
            'false'
          )
        )
      )
    },
  [UniNoticeType.enable_setting_messages_only_from_verified]:
    () => async (dispatch, getState) => {
      return dispatch(
        replace(
          mergeAllUrls(
            getState().systemReducer.baseUrl,
            settingsModalPath,
            settingsFieldPath,
            Fields.Message
          )
        )
      )
    },
}

export type NoticeBackHandlerTypes = () => AsyncThunkAction

export type NoticeBackHandlersMap = Record<
  | UniNoticeId.fill_profile_for_advanced_search
  | UniNoticeId.rating_advanced_filters_restricted
  | UniNoticeType.liveness_verification_failed
  | UniNoticeType.liveness_verification_success,
  NoticeBackHandlerTypes
>

export const noticeBackHandlersMap: NoticeBackHandlersMap = {
  [UniNoticeId.fill_profile_for_advanced_search]: () => (dispatch) => {
    dispatch(fullResetSearchFormAction())
    dispatch(clearSearchListProfileAction())
  },
  [UniNoticeId.rating_advanced_filters_restricted]: () => (dispatch) => {
    dispatch(combineNormalizeRestartPhotoRatingAction())
  },
  [UniNoticeType.liveness_verification_failed]: () => (dispatch) => {
    dispatch(resetLivenessStatusAction())
  },
  [UniNoticeType.liveness_verification_success]: () => (dispatch) => {
    dispatch(resetLivenessStatusAction())
  },
}

export const availableCommonActions = Object.keys(commonHandlersMap)

export const handleUniNoticeAction =
  (
    actionId: UniNoticeIdTypes,
    noticeId: string,
    data: UniNoticeData,
    type: UniNoticeViewTypes = TYPE_NOTIFICATION,
    display: UniNoticeDisplayTypes = DISPLAY_CONTAINER
  ): AsyncThunkAction =>
  (dispatch, getState) => {
    const {
      uniNoticeReducer: { collection },
      noticeShortCut: { collection: collectionNoticeShortCut },
    } = getState()
    const notice = collection[noticeId]
    const noticeShortCut = collectionNoticeShortCut[noticeId]
    const handlerAction = commonHandlersMap[actionId]
    const _notice = type === TYPE_SHORTCUT ? noticeShortCut : notice
    if (handlerAction) {
      dispatch(handlerAction(data, noticeId, _notice, type, display))
    } else {
      console.error(`Handler not found for action id: "${actionId}"`)
    }
  }

export const handleUniNoticeBackAction =
  (noticeId: string): AsyncThunkAction =>
  (dispatch) => {
    const handlerAction = noticeBackHandlersMap[noticeId]

    if (handlerAction) {
      dispatch(handlerAction())
    }
  }
