import { fetchUniNoticeAction } from 'actions/uninotice/uniNoticeAction'
import { requestIncognitoApi } from 'api/incognito/incognitoRequestApi'
import { fetchAllPhotosFromAlbumsApi } from 'api/profileApi'
import { incognitoRequiredSentNoticeId } from 'components/uninotice/uniNoticeIdList'

export const CHANGE_INTERACTION_STATUS = 'CHANGE_INTERACTION_STATUS' as const
export const FETCH_PHOTOS = 'FETCH_PHOTOS' as const
export const REQUEST_INCOGNITO_ACTION = 'REQUEST_INCOGNITO_ACTION' as const
export const HIDE_AUTHOR_ACTION = 'HIDE_AUTHOR_ACTION' as const

export const fetchUserPhotosAction = (userId, headers, locale) => ({
  type: FETCH_PHOTOS,
  promise: () => fetchAllPhotosFromAlbumsApi(userId, headers, locale),
})

export const changeInteractionStatusAction = (service, status) => ({
  type: CHANGE_INTERACTION_STATUS,
  service,
  status,
})

export const requestIncognitoAction = (userId) => (dispatch) => {
  // Пока тут останется, как только перейдем на новую анкету, надо будет открыть
  // const { router } = getState()
  // const userId = findUserId(router)
  dispatch({
    type: REQUEST_INCOGNITO_ACTION,
    promise: async () => {
      const response = await requestIncognitoApi(userId)
      if (response.ok) {
        dispatch(fetchUniNoticeAction(incognitoRequiredSentNoticeId))
      }
      return response
    },
  })
}

export const CLEAN_FOREIGN_PROFILE = 'CLEAN_FOREIGN_PROFILE' as const

export const cleanForeignProfileAction = () => ({ type: CLEAN_FOREIGN_PROFILE })
